import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const WORKTIME_TASK_FRAGMENT = gql`
  fragment WorktimeTaskFragment on WorktimesTask {
    id
    name
    type
    paid
    productive
    sidebar
    position
    acdId

    insertedAt
    updatedAt
  }
`;

export const WORKTIMES_TASKS_LIST_QUERY = gql`
  query worktimesTasks($customerId: ID!) {
    worktimesTasks(customerId: $customerId) {
      ...WorktimeTaskFragment
    }
  }
  ${WORKTIME_TASK_FRAGMENT}
`;

export const WORKTIME_TASK_QUERY = gql`
  query worktimesTask($customerId: ID!, $id: ID!) {
    worktimesTask(customerId: $customerId, id: $id) {
      ...WorktimeTaskFragment
    }
  }
  ${WORKTIME_TASK_FRAGMENT}
`;

export const WORKTIME_TASK_MUTATION = gql`
  mutation mutateWorktimesTask($customerId: ID!, $id: ID, $values: WorktimesTaskInput!) {
    mutateWorktimesTask(customerId: $customerId, id: $id, values: $values) {
      ...WorktimeTaskFragment
    }
  }
  ${WORKTIME_TASK_FRAGMENT}
`;

export const DELETE_WORKTIME_TASK_QUERY = gql`
  mutation deleteWorktimesTask($customerId: ID!, $id: ID!) {
    deleteWorktimesTask(customerId: $customerId, id: $id) {
      ...WorktimeTaskFragment
    }
  }
  ${WORKTIME_TASK_FRAGMENT}
`;

export const WORKTIMES_TASK_TYPES = [
  "WORK",
  "PAUSE",
  "ILLNESS",
  "CHILD_SICK",
  "SICK_NO_PAYMENT",
  "HOLIDAYS",
  "FEAST_DAY",
  "NOT_AVAILABLE",
  "MINUS",
  "COMPENSATION",
];

export const WORKTIME_WO_USER_FRAGMENT = gql`
  fragment WorktimeWoUserFragment on Worktime {
    id
    starts
    stops
    userId
    comment
    declined
    declineReason
    billingCustomerId

    customerId

    task {
      id
      name
      type
      paid
    }
  }
`;

export const WORKTIME_FRAGMENT = gql`
  fragment WorktimeFragment on Worktime {
    id
    starts
    stops

    comment
    declined
    declineReason

    acdId
    customerId
    billingCustomerId
    customerReviewDone

    userId
    user {
      ...UserFragment
    }

    taskId
    task {
      id
      type
      name
      paid
    }
  }
  ${USER_FRAGMENT}
`;

export const WORKTIMES_LIST_QUERY = gql`
  query worktimes($customerId: ID!, $filters: WorktimesListFilters) {
    worktimes(customerId: $customerId, filters: $filters) {
      ...WorktimeFragment
    }
  }
  ${WORKTIME_FRAGMENT}
`;

export const WORKTIMES_W_BILLING_CUSTOMER_QUERY = gql`
  query worktimes($customerId: ID!, $filters: WorktimesListFilters) {
    worktimes(customerId: $customerId, filters: $filters) {
      ...WorktimeFragment

      billingCustomer {
        id
        name

        logo {
          thumb
          medium
          original
        }
      }
    }
  }
  ${WORKTIME_FRAGMENT}
`;

export const COUNT_WORKTIMES_QUERY = gql`
  query countWorktimes($customerId: ID!, $filters: WorktimesListFilters) {
    countWorktimes(customerId: $customerId, filters: $filters)
  }
`;

export const WORKTIMES_LIST_WO_USER_QUERY = gql`
  query worktimes($customerId: ID!, $filters: WorktimesListFilters) {
    worktimes(customerId: $customerId, filters: $filters) {
      ...WorktimeWoUserFragment
    }
  }
  ${WORKTIME_WO_USER_FRAGMENT}
`;

export const WORKTIMES_SUBSCRIPTION = gql`
  subscription worktimes($customerId: ID!) {
    worktimeDayStatus(customerId: $customerId) {
      worktime
      runningTask {
        ...WorktimeWoUserFragment
      }
    }
  }
  ${WORKTIME_WO_USER_FRAGMENT}
`;

export const WORKTIME_GET_QUERY = gql`
  query worktime($customerId: ID!, $id: ID!) {
    worktime(customerId: $customerId, id: $id) {
      ...WorktimeFragment
    }
  }
  ${WORKTIME_FRAGMENT}
`;

export const WORKTIME_GET_W_BILLING_CUSTOMER_QUERY = gql`
  query worktime($customerId: ID!, $id: ID!) {
    worktime(customerId: $customerId, id: $id) {
      ...WorktimeFragment

      billingCustomer {
        id
        name

        logo {
          thumb
          medium
          original
        }
      }
    }
  }
  ${WORKTIME_FRAGMENT}
`;

export const WORKTIME_START_STOP_VALID_QUERY = gql`
  query worktimeStartEndValid($customerId: ID!, $filters: WorktimeValidationInput!) {
    worktimeStartEndValid(customerId: $customerId, filters: $filters) {
      valid
      worktimes {
        ...WorktimeFragment
      }
    }
  }
  ${WORKTIME_FRAGMENT}
`;

export const WORKTIME_MUTATION = gql`
  mutation worktimeMutation($customerId: ID!, $id: ID, $worktime: WorktimeInput!, $byContract: Boolean, $days: Int) {
    mutateWorktime(customerId: $customerId, id: $id, worktime: $worktime, byContract: $byContract, days: $days) {
      ...WorktimeFragment
    }
  }
  ${WORKTIME_FRAGMENT}
`;

export const DELETE_WORKTIME_MUTATION = gql`
  mutation deleteWorktime($customerId: ID!, $id: ID!) {
    deleteWorktime(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const WORKTIME_START_TASK = gql`
  mutation startWorktime($customerId: ID!, $taskId: ID!) {
    startWorktime(customerId: $customerId, taskId: $taskId) {
      ...WorktimeWoUserFragment
    }
  }
  ${WORKTIME_WO_USER_FRAGMENT}
`;

export const WORKTIME_STOP_TASK = gql`
  mutation stopCurrentWorktime($customerId: ID!) {
    stopCurrentWorktime(customerId: $customerId) {
      ...WorktimeWoUserFragment
    }
  }
  ${WORKTIME_WO_USER_FRAGMENT}
`;

export const WORKTIME_CONFIRM_DAY_MUTATION = gql`
  mutation confirmDayWorktimes($customerId: ID!, $date: NaiveDate!) {
    confirmDayWorktimes(customerId: $customerId, date: $date) {
      id
    }
  }
`;

export const WORKTIME_DECLINE_DAY_MUTATION = gql`
  mutation declineDayWorktimes($customerId: ID!, $date: NaiveDate!, $reason: String!) {
    declineDayWorktimes(customerId: $customerId, date: $date, reason: $reason) {
      id
    }
  }
`;

export const WORKTIMES_W_CUSTOMER_DATA_LIST_QUERY = gql`
  query worktimes($customerId: ID!, $filters: WorktimesListFilters) {
    worktimes(customerId: $customerId, filters: $filters) {
      ...WorktimeFragment

      customer {
        id
        name

        logo {
          thumb
          medium
          original
        }
      }

      billingCustomer {
        id
        name

        logo {
          thumb
          medium
          original
        }
      }
    }
  }
  ${WORKTIME_FRAGMENT}
`;

export const WORKTIME_CONFIRM_CUSTOMER_MUTATION = gql`
  mutation worktimesConfirmCustomer($customerId: ID!, $worktimeIds: [ID!]!) {
    worktimesConfirmCustomer(customerId: $customerId, worktimeIds: $worktimeIds) {
      id
    }
  }
`;

export const WORKTIME_CUSTOMER_MUTATION = gql`
  mutation worktimesMutateCustomer($customerId: ID!, $userId: ID!, $values: [WorktimesCustomerInput]!) {
    worktimesMutateCustomer(customerId: $customerId, userId: $userId, values: $values) {
      id
    }
  }
`;

export const WORKTIME_DAY_STATUS_QUERY = gql`
  query worktimeDayStatus($customerId: ID!) {
    worktimeDayStatus(customerId: $customerId) {
      worktime
      runningTask {
        ...WorktimeWoUserFragment
      }
    }
  }
  ${WORKTIME_WO_USER_FRAGMENT}
`;
